const RoleType = {
  student: 1,
  coach: 2,
  admin: 3,
};

const RoleMap = {
  1: '學生',
  2: '教練',
  3: '潔西水水',
};

const MediaType = {
  image: 1,
  video: 2,
};

const MediaMap = {
  1: '圖片',
  2: '影片',
};

const MedalType = {
  1: '課程等級',
  2: 'PB 蒐集',
  3: '怪奇系統',
  4: '潛點紀錄',
};

const Pattern = {
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  NICKNAME: /[AM][01234]P?\/\d+\/.+/,
};

const ErrorCode = {
  USER_ALREADY_EXISTS: 1003,
  UNVERIFIED: 1004,
  DATA_NOT_FOUND: 2022,
  POINT_UNSATISFIED: 2023,
  DATA_ALREADY_EXIST: 2024,
  TOO_MANY_REQUEST: 2025,
  INVALIDATE_USERNAME: 1013,
  INVALIDATE_NICKNAME: 1014,
  INVALIDATE_SEX: 1015,
  INVALIDATE_LEVEL: 1016,
  NICKNAME_AND_LEVEL_MISMATCH: 1017,
  MISSING_REQUIRED_PROFILE: 1018,
  TRAINING_QUOTA_INSUFFICIENT: 5002,
  TRAINING_RESERVATION_ALREADY_PAID_OR_CONFIRMED: 5003,
  TRAINING_RESERVATION_STATUS_INVALIDATE: 5004,
  CANNOT_CANCEL_TRAINING_WITHIN_5_DAYS: 5005,
  TRAINING_IS_PENDING: 5006,
  CANNOT_CANCEL_NOT_YOUR_TRAINING: 5007,
  CANNOT_UPDATE_NOT_YOUR_TRAINING: 5008,
  QUANTITY_UNDER_LIMIT: 5009,
  CANNOT_RESERVE_TOMORROW_TRAINING: 5010,
};

const ErrorMsgMap = {
  1004: '尚未驗證, 請通知教練審核',
  1013: '帳號格式錯誤, 不可包含 英文/數字/_ 外符號',
  1014: '暱稱格式錯誤, ex: A1/20230101/小光',
  1015: '性別格式錯誤',
  1016: '等級格式錯誤',
  1017: '暱稱與等級不符',
  1018: '請補齊個人檔案資料',
  2025: '請求過快, 請稍後重試',
  5002: '團練/跟課 名額不足',
  5003: '團練/跟課 已付款, 無法取消',
  5004: '更新團練狀態錯誤',
  5005: '團練前5天內不可取消',
  5006: '已保留團練/跟課, 欲取消請聯絡教練',
  5007: '不能取消不是你的團練',
  5008: '不能更新不是你的團練',
  5009: '更新團練人數有誤',
  5010: '團練前一天不可預約',
};

const LevelMap = {
  0: 'AIDA0',
  1: 'AIDA1',
  2: 'AIDA2P',
  3: 'AIDA2',
  4: 'AIDA3P',
  5: 'AIDA3',
  6: 'AIDA4P',
  7: 'AIDA4',
  8: 'COACH',
  9: 'M0',
  10: 'M1',
  11: 'M2',
};

const LevelReversedMap = {
  AIDA0: 0,
  AIDA1: 1,
  AIDA2P: 2,
  AIDA2: 3,
  AIDA3P: 4,
  AIDA3: 5,
  AIDA4P: 6,
  AIDA4: 7,
  COACH: 8,
  M0: 9,
  M1: 10,
  M2: 11,
};

const TrainingType = {
  1: '自潛團練',
  2: '自潛跟課',
  3: '人魚團練',
  4: '人魚跟課',
};

const TrainingTypeReversedMap = {
  自潛團練: 1,
  自潛跟課: 2,
  人魚團練: 3,
  人魚跟課: 4,
};

const TrainingReservationStatus = {
  RECEIVED: 1, // 初始化狀態
  PENDING: 2, // 預約泳池成功, 可付款
  PAID: 3, // 已通知付款
  CONFIRMED: 4, // 收到付款完成流程
};

const TrainingReservationStatusMap = {
  1: '未預約泳池',
  2: '未付款',
  3: '已付款',
  4: '已完成',
};

export { Pattern, ErrorCode, ErrorMsgMap, RoleType, MediaType, RoleMap, MediaMap, MedalType, LevelMap, LevelReversedMap, TrainingType, TrainingTypeReversedMap, TrainingReservationStatus, TrainingReservationStatusMap };
