import { LevelMap } from '@/assets/constant/constant';

export default {
  data() {
    return {
      tabName: this.$route.name.toLowerCase(),
      pathMap: {
        home: '/',
        user: '/user',
        auth: '/auth',
        profile: '/profile',
        medal: '/medal',
        medalLog: '/medal-log',
        marquee: '/marquee',
        training: '/training',
        service: '/service',
      },
      guestPathMap: {
        home: '/',
        auth: '/auth',
        training: '/training-guest',
        service: '/service',
      },
    };
  },
  computed: {
    AIDALevel() {
      return LevelMap[this.$parent.user.level];
    },
  },
  methods: {
    redirectPage(name) {
      const map = this.$parent.isSignIn ? this.pathMap : this.guestPathMap;
      const path = map[name];
      if (!path) {
        this.$router.push('/');
      } else {
        this.$router.push(path);
      }
    },
  },
};
